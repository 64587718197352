
import { computed, defineComponent, ref, onMounted, h } from "vue";
import { useStore } from "vuex";
import { APIDataProps, AutoTableCellProps, OptionProps } from "@/types";
import { SelfProps } from "@/store";
import deepClone from "@/utils/deepClone";
import { filterCombinability } from "@/API/program";
import {
  ColumnsProps,
  ProgramCodeProps,
  CategoryProps,
  GroupTableProps,
} from "../types";
import { getColumsCS } from "./utils";
export default defineComponent({
  setup() {
    const store = useStore();
    // offer left
    const selfLeftData = computed(() => {
      return store.state.pgmCombinability.offerOther.internal;
    });
    // offer Midd
    const leftData = ref<CategoryProps[]>([]);
    // offer right
    const middData = ref<ProgramCodeProps[]>([]);
    // offer
    const offerData = ref<SelfProps[]>([]);

    const isFlag = ref<boolean>(false);

    const isActiveLeft = ref<string>();
    const isActive = ref<string>();
    const isActiveRight = ref<string>();
    const handleActive = (
      id: string,
      direction: string,
      offerType: SelfProps,
      offerTable: APIDataProps
    ) => {
      const objArr = deepClone(store.state.pgmCombinability);
      if (direction === "left") {
        isActiveLeft.value = id;
        objArr.target = {
          entry: "OfferOtherProgramOffer",
          internalOffer: {
            offerTypeKey: offerType.offerTypeKey,
            tableId: offerTable.tableId,
            rowId: id,
          },
        };
      }
      if (direction === "midd") {
        isActive.value = id;
        objArr.target = {
          entry: "OfferOtherProgramCategory",
          otherCategory: id,
        };
      }
      if (direction === "right") {
        isActiveRight.value = id;
        objArr.target = {
          entry: "OfferOtherProgramCategoryProgram",
          otherProgram: id,
        };
      }
      filterCombinability(objArr).then((res) => {
        store.commit("updatePgmCombinability", res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category;
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
        if (offerData.value.length > 0)
          getColumsCS(offerData.value as SelfProps[]);
      });
    };
    // 点击右侧的勾选状态， true/false
    const handleChangeCom = (
      row: AutoTableCellProps,
      offerType: string,
      tableId: string
    ) => {
      const objArr = deepClone(store.state.pgmCombinability);
      objArr.target = {
        entry: "OfferOtherProgramCategoryProgramOfferChecked",
        otherOffer: {
          offerTypeKey: offerType,
          tableId: tableId,
          rowId: row.id,
        },
      };
      filterCombinability(objArr).then((res) => {
        store.commit("updatePgmCombinability", res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category;
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
        if (offerData.value.length > 0)
          getColumsCS(offerData.value as SelfProps[]);
      });
    };
    // 切换分类信息，如果能找到一个是isVisible:true，那么groupName就显示
    const handleChangeCate = () => {
      leftData.value.forEach((item) => {
        item.isVisible = item.offerType.some((val) => val.isVisible);
      });
    };
    // 点击左侧，中间的勾选状态，有三种状态，0:叉, 1:勾, 2:-
    const handleChangeMidd = (row: OptionProps, direction: string) => {
      const objArr = deepClone(store.state.pgmCombinability);
      if (direction === "midd") {
        isActive.value = row.id;
        objArr.target = {
          entry: "OfferOtherProgramCategoryChecked",
          otherCategory: row.id,
        };
      }
      if (direction === "right") {
        isActive.value = row.id;
        objArr.target = {
          entry: "OfferOtherProgramCategoryProgramChecked",
          otherProgram: row.id,
        };
      }
      filterCombinability(objArr).then((res) => {
        store.commit("updatePgmCombinability", res);
        leftData.value = store.state.pgmCombinability.offerOther.other.category;
        middData.value = store.state.pgmCombinability.offerOther.other.program;
        offerData.value = store.state.pgmCombinability.offerOther.other.offer;
      });
    };
    return {
      isFlag,
      leftData,
      middData,
      selfLeftData,
      offerData,
      isActive,
      isActiveRight,
      handleActive,
      handleChangeCom,
      handleChangeMidd,
      isActiveLeft,
      handleChangeCate,
    };
  },
});
